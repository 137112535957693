import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"

export default function Home() {
  return (
    <Layout>
      <div>
        <h3 className="text-3xl">Pre word</h3>
        <br />
        <p>
          The Experiment of Salc1 leading an Anarach Server has come to an end.
        </p>
        <p>
          Shortly after the server closed, Sal released the hole map with player
          data and the coreprotect(a plugin that logs every interatction with
          the world, eg. placing a block, destroing it) database.
        </p>
        <p>
          And the work begun, making a world render, gathering statics,
          searching thru Inventroys and chests for illigal items, getting player
          that a in gamemode, converting every map to an Image, getting all the
          sign data and much more.
        </p>
        <br />
        <br />
        <h3 className="text-3xl">Map Art</h3>
        <br />
        <p>
          Because we got the hole map with every thing in it, not like the 2b2t
          maps that were taken withe world downloader mod. We have acces to the
          files, were all maps are stored.
        </p>
        <p>
          So i was possible to create a programm that reads that files and
          create Images from that so we can look at them without starting
          minecraft.
        </p>
        <p>
          The normal Map size is 128x128 Block because that is so tiny they got
          upscaled by 8 -&gt; 1024x1024 pixel so you can acctulay see somthing
          on the images.
        </p>
        <p>(WARNING: CONTAINS NSFW STUFF)</p>
        <a
          className="underline"
          href="/dls/maps_as_1024x1024px_png_with_cords.zip"
          aria-label="Download (~35.6MB)"
        >
          <strong>
            <p>Download (~35.6MB)</p>
          </strong>
        </a>
        <br />
        <br />
        <h3 className="text-3xl">Render</h3>
        <br />
        <p>
          The world is uncompressed 282 GB large so it is near impossible to
          just fly thru the hole world and visiting/searching for Buildings
          normaly even with utility like new Chunks.
        </p>
        <p>
          What is the next logical step to that right lets to a render so you
          can vistist every place on the map very easy and you dont have to
          download the world just look at it in the Browser.
        </p>
        {/* <p>Beacuse viewing spesific cordinages is a bit difficulty we made the side (EZ CORDS) were you just enter the cordinates you want to visit and press calculate and you get a link easy to click and get to the position you want.</p> */}
        <p>OVERWORLD ONLY FOR NOW. NETHER AND END WIP</p>
        <Link className="underline" to="/render">
          <strong>
            <p>Link to the Render</p>
          </strong>
        </Link>
        <br />
        <br />
        <h3 className="text-3xl">Inv-Viewer</h3>
        <br />
        <p>
          Because looking in inventory is quiet difficult by default we made an
          Webbased Invenitory viewer were you enter the name of the Person that
          you want to view the Invenotry from and it will show you the
          invenotry.
        </p>
        <p>
          Combined with the maps as png we can even see what map look like that
          a person has in its invenotry
        </p>
        <Link className="underline" to="/inv-viewer">
          <strong>
            <p>Link to the Inv-Viewer</p>
          </strong>
        </Link>
        <br />
        <br />
        <h3 className="text-3xl">Region Requester</h3>
        <br />
        <p>
          As we already mentioned the world is large so not everyone can
          download the whole world.
        </p>
        <p>
          For people who just want a part of the map (eg. there base) they can
          request that with the Region Requester.
        </p>
        <p>How does the Region Requester work?</p>
        <p>
          Just enter 2 cordinates of the area you want to download, press
          calculate it will check you inputs say how mutch regions are in the
          download and if this spesific area already got requested you get a
          download link, if not and all paramenters a right press genzip and it
          will generate you a world download for this spesifc region this may
          take some seconds. (max. 100 region files per run. multiple runs a
          possible but dont spam!)
        </p>
        <p>
          The data for maps and player data is seperate available just donwload
          it if you want further instruction a in the zip.
        </p>
        <Link className="underline" to="/region-requester">
          <strong>
            <p>Link to the Region Requester</p>
          </strong>
        </Link>
      </div>
    </Layout>
  )
}
